import ReactDOM from 'react-dom';
import { useBodyScrollLock } from 'hooks';
import { CloseSvg } from 'svg';

function Modal (props) {
  const {
    children,
    show = false,
    close = () => null,
    className,
  } = props;
  const classes = `modal${className ? ' ' + className : ''}`;
  useBodyScrollLock(show);

  return (
    show &&
    ReactDOM.createPortal(
      <div className={classes}>
        <div className="modal__backdrop" onClick={() => close()} />
        <div className="modal__wrap">
          <div className="modal__wrapper">
            <div className="flex justify-end">
              <div className="modal__close-button" onClick={() => close()}>
                <CloseSvg />
              </div>
            </div>
            <div className="modal__content">{children}</div>
          </div>
        </div>
      </div>,
      document.body
    )
  );
}
export default Modal;
