import {GlobalInfo, Partners} from './components';
import {useRegionData} from 'hooks';
import {getLayoutContent} from "helpers";
import FooterColumn from './components/FooterColumn';

const Footer = (props) => {
  const {className} = props;
  const { region } = useRegionData('footer-region');

  const classes = `footer-section${className ? ' ' + className : ''}`;

  if (!region.content) return null

  const underLogo = getLayoutContent(region.content, 'under_logo')
  // const {attributes: {contact_list: contactList} = {}} = getLayoutContent(region.content, 'contacts') || {}
  const { attributes: partners } = getLayoutContent(region.content, 'partners') || {}

  return (
    <>
      <div className="stickyFooter" />
      <footer className={classes}>
        <div className="container">
          <div className="footer">
            <div className="footer__wrapper">
              {region.content && <GlobalInfo className="footer__global-info" list={underLogo?.attributes?.content} />}
              <div className="footer__columns">
                {region?.columns && region?.columns.map(column => <FooterColumn key={column.key}
                  className={column.attributes?.classes}
                  items={column.attributes?.items}
                  title={column.attributes?.title}
                  smallGap={column.attributes?.small_gap}
                />)}
              </div>
            </div>

            {partners && <Partners className="footer__partners" title={partners?.partner_title} list={partners?.partner_list} />}
            {/* {contactList && <Socials className="footer__socials" list={contactList} />} */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
