import React from 'react'
import { useSelector } from 'react-redux';
import { ProductPreview } from "components";

const Accessories = (props) => {
  const columns = useSelector((state) => state.globalContent.shopColumns);

  return (
    <section className={`accessories-section${props.className ? ` ${props.className}` : ''}`}>
      <div className="container">
        <div
          className="accessories"
          style={{ '--_columns': columns || 3 }}
        >
          {props?.content?.map((acc, key) => <React.Fragment key={key}><ProductPreview content={acc} /></React.Fragment>)}
        </div>
      </div>
    </section>
  );
};

export default Accessories;
