import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'
import { usePageData, useTranslatableContent } from 'hooks'

export default function PageSeo () {
  const page = usePageData();
  const { t, i18n } = useTranslation('common');
  const maybeTranslate = useTranslatableContent();

  const data = {
    description: page?.seo?.description ? maybeTranslate(page.seo.description) : t('site.description'),
    image: page?.seo?.image ? page.seo.image : null,
    siteName: t('site.name'),
    title: page?.seo?.title ? maybeTranslate(page.seo.title) + ' — ' + t ('site.title'): t('site.title'),
    url: window.location.href,
  };

  let product = null;
  if (page?.template === 'camper-page') {
    const camper = page?.data?.content;

    if (camper) {
      const images = camper?.media?.map(image => image.url) || [];

      if (camper?.featured_image) {
        images.unshift(camper.featured_image.url);
      }

      data.title = maybeTranslate(camper.name) + ' — ' + t('site.title');

      product = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: maybeTranslate(camper.name),
        // description: maybeTranslate(camper.description),
        image: images,
        offers: {
          '@type': 'Offer',
          priceCurrency: 'EUR',
          price: camper.price,
          availability: 'https://schema.org/InStock',
          url: data.url,
        },
      };
    }

  } else if (page?.template === 'accessory-page') {
    const accessory = page?.data?.content?.find(block => block.layout === 'details')?.attributes || null;

    if (accessory) {
      data.title = maybeTranslate(accessory.name) + ' — ' + t('site.title');
      data.description = maybeTranslate(accessory.description) ?? data.description;

      product = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: maybeTranslate(accessory.name),
        description: maybeTranslate(accessory.description),
        image: accessory?.media?.map(image => image.url) || [],
        offers: {
          '@type': 'Offer',
          priceCurrency: 'EUR',
          price: accessory.price,
          availability: accessory.stock_quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
          url: data.url,
        },
      };
    }
  }

  return <Helmet>
    <html lang={i18n.language} />

    {/* Open Graph meta tags*/}
    <meta property="og:title" content={data.title} />
    <meta property="og:description" content={data.description} />
    <meta property="og:url" content={data.url} />
    {data?.image && <meta property="og:image" content={data.image} />}
    <meta property="og:site_name" content={data.siteName} />

    {/* Standard meta tags */}
    <title>{data.title}</title>
    <meta name="description" content={data.description} />
    <link rel="canonical" href={data.url} />

    {/* Product meta tags */}
    {product && <script type="application/ld+json">
      {JSON.stringify(product)}
    </script>}
  </Helmet>
}
