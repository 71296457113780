import { NavLink } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const Pagination = (props) => {
  let [searchParams] = useSearchParams();

  if (!props?.data?.paths || props.data.paths.length < 2) {
    return null
  }

  // if (props?.correct) {

  //   return (
  //     <section className={`pagination-section${props.className ? ` ${props.className}` : ''}`}>
  //       <div className="container">
  //         <div className="pagination">
  //           {props.data.paths.map((item, i) => {
  //             if (item === false) {
  //               return <span key={i} className="separator">...</span>;
  //             }

  //             const searchParams = new URLSearchParams(item.path.split('?')[1] || '');
  //             const page = searchParams.get('page') || 1;

  //             return (
  //               <NavLink className={item.numb === page ? 'active' : ''} key={i} to={item.path}>
  //                 {item.numb}
  //               </NavLink>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </section>
  //   );
  // }

  const path = window.location.pathname;
  const params = new URLSearchParams(searchParams);
  const sp = params.toString();

  const url = (path) => path.replace(/\/1(?![0-9/])/g, '') + (sp ? `?${sp}` : '');

  return (
    <section className={`pagination-section${props.className ? ` ${props.className}` : ''}`}>
      <div className="container">
        <div className="pagination">
          {props.data.paths.map((item, i) => {
            if (item === false) {
              return <span key={i} className="separator">...</span>;
            }

            return (
              <NavLink className={() => item.path.replace(/\/1(?![0-9/])/g, '') === path ? 'active' : ''} key={i} to={url(item.path)}>
                {item.numb}
              </NavLink>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Pagination;
