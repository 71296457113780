import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setShopColumns } from 'store/GlobalContentSlice';
import RangeSlider from 'components/Fields/RangeSlider';
import PerPage from 'components/Filters/PerPage';
import Sort from 'components/Filters/Sort'

export default function Filters (props) {
  const columns = useSelector((state) => state.globalContent.shopColumns);
  const dispatch = useDispatch();

  return (
    <section className={`filters-section${props.className ? ' ' + props.className : ''}`}>
      <div className="container">
        <div className="flex items-center gap-4">
          {props?.content?.map((filter, key) => <React.Fragment key={key}>{filter}</React.Fragment>)}
          <Sort />
          <PerPage />
          <RangeSlider
            className="ml-auto"
            max="5"
            min="2"
            onChange={(event) => dispatch(setShopColumns(event.target.value))}
            value={columns}
          />
        </div>
      </div>
    </section>
  );
};
