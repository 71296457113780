import { useSelector } from 'react-redux';

/**
 * Returns the path for a route matching the given template, with the current active locale.
 * If there are multiple pages with the same template, the first one will be picked
 */
export default function usePathFromTemplate(templateName, params = {}) {
  const {locale} = useSelector(state => state.i18n)
  const {routes} = useSelector(state => state.globalContent)
  const path = routes.find(x => x.template === templateName && x.locale === locale)?.path

  if (!path) return '/'

  return Object.entries(params).reduce((pathWithParams, [key, value]) => {
    return pathWithParams.replace(`:${key}`, value)
  }, path)
}
