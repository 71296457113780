import { Field } from 'formik';
import _ from 'lodash';

const Input = (props) => {
    const {
        name,
        className,
        onBlur,
        values,
        touched,
        errors,
        label,
        disabled,
        as
    } = props;

    const classes = `field-wrap${className ? ' ' + className : ''} ${touched[name] && errors[name] ? ' field-wrap-error' : ''}`;

    return (
        <div className={classes}>
            <Field
                as={as}
                name={name}
                id={name}
                onBlur={(e) => onBlur(e)}
                value={_.get(values, name, '')}
                placeholder=" "
                disabled={disabled}
            />
            <label aria-labelledby={name} htmlFor={name}>{touched[name] && errors[name] ? errors[name] : label}</label>
        </div>
    );
};

export default Input;
