import classNames from "classnames";

const RedQuantity = ({ className, quantity }) => {
    
    const classes = classNames('red-quantity', className ? ' ' + className : '');
    
    return (
        <span className={classes}>{quantity}</span>
    );
};

export default RedQuantity;
