import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { HeartSvg } from 'svg';
import { RedQuantity } from 'ui';

export default function Favourites (props) {
   
    const { routes } = useSelector((state) => state.globalContent);
    const route = routes.find((route) => route.template === 'favourites');

    const favourites = useSelector((state) => state.favourites.value)

    const classes = `wish-list${props.className ? ' ' + props.className : ''}`;

    return (route && route.path) ? (
        <NavLink to={route.path} className="relative">
            {favourites?.length ? <RedQuantity quantity={favourites.length} /> : null}
            <HeartSvg className={classes} />
        </NavLink>
    ) : null;
}