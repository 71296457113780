import { useLocation } from 'react-router-dom';
import {findMatchingRedirectPath} from "../helpers";
import {useSelector} from "react-redux";

const usePathname = () => {
  const location = useLocation();
  const { redirectPaths } = useSelector((state) => state.globalContent);

  function maybeUsesAlias (path) {
    if (path === '/tarvikud/telksaunadee-saunad') return '/tarvikud/telgid/telksaunad';
    if (path === '/uudised/teemaksud-euroopas') return '/uudis/teemaksud-euroopas';
    // if (path === '/matkasuvilad/hymer') return '/matkasuvilad';
    if (path === '/uudised/tagged/matkabuss') return '/matkasuvilad';
    // if (path === '/matkasuvilad/hobby_cars') return '/matkasuvilad';
    if (path === '/uudised/tagged/autosuvila') return '/matkasuvilad';
    return path;
  }

  const path = maybeUsesAlias(location.pathname);

  return findMatchingRedirectPath(redirectPaths, path) || path;
}

export default usePathname
