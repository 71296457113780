import { NavLink } from 'react-router-dom';

const Menu = (props) => {
  const { className, menuList } = props;

  const classes = `menu${className ? ' ' + className : ''}`;

  const getPath = (menuPoint) => {
    return menuPoint.data?.custom_path ? menuPoint.data.custom_path : menuPoint.value.path
  }

  const hasPath = (item) => {
    return item?.value?.path || item?.data?.custom_path
  }

  return (
    <nav className={classes}>
      <ul className="menu__list">
        {menuList?.map((point) => {
          return (
            <li key={point.id} className="menu__item">
              {hasPath(point) ? (
                <NavLink
                  to={getPath(point)}
                  target={point.target}
                  className="menu__link"
                >
                  {point.name}
                </NavLink>
              ) : (
                <span className="menu__link">{point.name}</span>
              )}
              {point.children ? (
                <ul className="submenu">
                  {point.children.map((subPoint) => (
                    <li key={subPoint.id} className="submenu__item">
                      {hasPath(subPoint) ? (
                        <NavLink
                          to={getPath(subPoint)}
                          target={subPoint.target}
                          className="menu__link"
                        >
                          {subPoint.name}
                        </NavLink>
                      ) : (
                        <span className="menu__link">{subPoint.name}</span>
                      )}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          )
        })}
      </ul>
    </nav>
  );
};

export default Menu;
