import { lazy } from 'react';

// Lazy loading for SVG components
const ArrowSvg = lazy(() => import('./ArrowSvg.jsx'));
const BedSvg = lazy(() => import('./BedSvg.jsx'));
const BlackBigArrowSvg = lazy(() => import('./BlackBigArrowSvg.jsx'));
const CartSvg = lazy(() => import('./CartSvg.jsx'));
const CircleBigArrowSvg = lazy(() => import('./CircleBigArrowSvg.jsx'));
const CircleBlackLogoSvg = lazy(() => import('./CircleBlackLogoSvg.jsx'));
const CircleLogoRedRowPinSvg = lazy(() => import('./CircleLogoRedRowPinSvg.jsx'));
const CircleSmallArrowSvg = lazy(() => import('./CircleSmallArrowSvg.jsx'));
const CircleTransparentLogoSvg = lazy(() => import('./CircleTransparentLogoSvg.jsx'));
const CloseSvg = lazy(() => import('./CloseSvg.jsx'));
const EmailSvg = lazy(() => import('./EmailSvg.jsx'));
const ErrorSvg = lazy(() => import('./ErrorSvg.jsx'));
const FaceBookSvg = lazy(() => import('./FaceBookSvg.jsx'));
const FilterSvg = lazy(() => import('./FilterSvg.jsx'));
const ForestSvg = lazy(() => import('./ForestSvg.jsx'));
const HeartSvg = lazy(() => import('./HeartSvg.jsx'));
const HousePlanSvg = lazy(() => import('./HousePlanSvg.jsx'));
const InstagramSvg = lazy(() => import('./InstagramSvg.jsx'));
const LogoIconSvg = lazy(() => import('./LogoIconSvg.jsx'));
const LogoSvg157x32 = lazy(() => import('./LogoSvg157x32.jsx'));
const LogoSvg236X48 = lazy(() => import('./LogoSvg236X48.jsx'));
const LogoSvg329x68 = lazy(() => import('./LogoSvg329x68.jsx'));
const MagnifierSvg = lazy(() => import('./MagnifierSvg.jsx'));
const MatkaSuviladSvg = lazy(() => import('./MatkaSuviladSvg.jsx'));
const MorningNewsSvg = lazy(() => import('./MorningNewsSvg.jsx'));
const QuestionMarkSvg = lazy(() => import('./QuestionMarkSvg.jsx'));
const RoadSvg = lazy(() => import('./RoadSvg.jsx'));
const ScalesSvg = lazy(() => import('./ScalesSvg.jsx'));
const SeatSvg = lazy(() => import('./SeatSvg.jsx'));
const TwitterSvg = lazy(() => import('./TwitterSvg.jsx'));
const YouTubeSvg = lazy(() => import('./YouTubeSvg.jsx'));
const BigRoadSvg = lazy(() => import('./BigRoadSvg.jsx'));
const CheckMarkSvg = lazy(() => import('./CheckMarkSvg.jsx'));

export {
  ArrowSvg,
  BedSvg,
  BlackBigArrowSvg,
  CartSvg,
  CircleBigArrowSvg,
  CircleBlackLogoSvg,
  CircleLogoRedRowPinSvg,
  CircleSmallArrowSvg,
  CircleTransparentLogoSvg,
  CloseSvg,
  EmailSvg,
  ErrorSvg,
  FaceBookSvg,
  FilterSvg,
  ForestSvg,
  HeartSvg,
  HousePlanSvg,
  InstagramSvg,
  LogoIconSvg,
  LogoSvg157x32,
  LogoSvg236X48,
  LogoSvg329x68,
  MagnifierSvg,
  MatkaSuviladSvg,
  MorningNewsSvg,
  QuestionMarkSvg,
  RoadSvg,
  ScalesSvg,
  SeatSvg,
  TwitterSvg,
  YouTubeSvg,
  BigRoadSvg,
  CheckMarkSvg,
};
