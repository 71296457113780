import { useRef } from 'react';
import Dropdown from 'components/Fields/Dropdown';
import { usePathname } from '../../hooks';
import { useDispatch } from 'react-redux';
import { loadPageContent } from '../../store/GlobalContentSlice';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function PerPage () {
  const pathname = usePathname();
  const dispatch = useDispatch();
  let [searchParams, /* setSearchParams */] = useSearchParams();
  const { t } = useTranslation('campers');
  const ref = useRef(null);
  const options = [
    {text: 25, value: '25'},
    {text: 50, value: '50'},
    {text: 100, value: '100'},
  ];

  const navigate = useNavigate();

  const updateSearchParams = (k) => {
    const params = new URLSearchParams(searchParams);
    params.set('per_page', k);

    const pathSegments = pathname.split('/');
    if (!isNaN(parseInt(pathSegments[pathSegments.length - 1]))) {
      pathSegments.pop();
    }
    const newPathname = pathSegments.join('/');

    navigate(`${newPathname}?${params.toString()}`);
    dispatch(loadPageContent({ path: `${newPathname}?${params.toString()}` }));
    // setSearchParams(params);
  };

  return (
    <div ref={ref}>
      <Dropdown
        label={t('field.per_page')}
        options={options}
        selected={searchParams.get('per_page') ?? '25'}
        setSelected={(k) => updateSearchParams(k)}
      />
    </div>
  );
};
