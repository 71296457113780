import { LogoSvg329x68 } from '../../../../svg';
import { NavLink } from 'react-router-dom';
const GlobalInfo = (props) => {
  const { className, list } = props;

  const classes = `global-info${className ? ' ' + className : ''}`;

  return (
    <div className={classes}>
      <NavLink to={'/'}>
        <LogoSvg329x68 className="global-info__logo-329x68" />
      </NavLink>

      <ul className="global-info__list">
        {list?.map((el, i) => (
          <li key={i} className="global-info__point">
            {el.attributes.label && (
              <span className="global-info__label">
                {el.attributes.label + ' '}
              </span>
            )}
            {el.attributes.link && <a
              className="global-info__content"
              {...(el.attributes.link_target && { target: '_blank' })}
              href={el.attributes.link}
            >
              {el.attributes.link_text}
            </a>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GlobalInfo;
