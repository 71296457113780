/**
 * This file contains the slice for the favourites (or wishlist) state.
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: JSON.parse(localStorage.getItem('favourites')) || []
};

export const favouritesSlice = createSlice({
    name: 'favourites',
    initialState,
    reducers: {
        addFavourite: (state, action) => {
            state.value.push(action.payload);
            localStorage.setItem('favourites', JSON.stringify(state.value));
        },
        removeFavourite: (state, action) => {
            state.value = state.value.filter((item) => item !== action.payload);
            localStorage.setItem('favourites', JSON.stringify(state.value));
        },
        toggleFavourite (state, action) {
            if (state.value.find((favourite) => favourite === action.payload)) {
                state.value = state.value.filter((favourite) => favourite !== action.payload);
                localStorage.setItem('favourites', JSON.stringify(state.value));
                return;
            }
    
            state.value = [...state.value, action.payload];
            localStorage.setItem('favourites', JSON.stringify(state.value));
        }
    }
});

export const { addFavourite, removeFavourite, toggleFavourite } = favouritesSlice.actions;
export default favouritesSlice.reducer;