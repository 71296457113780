import React from 'react';

export default function RangeSlider (props) {
  return (
    <input
      className={`range-slider${props.className ? ` ${props.className}` : ''}`}
      type="range"
      min={props.min}
      max={props.max}
      value={props.value}
      onChange={props.onChange}
    />
  );
}
