import React, { useEffect, useState } from 'react'
import ContactModal from 'components/Modals/ContactModal'
import { useTranslation } from 'react-i18next'
import { usePageData, useRegionData, useTranslatableContent } from 'hooks'
import placeholder from 'img/ImagePlaceholder.jpg'
import { toggleContactModal } from 'store/GlobalContentSlice';
import { useDispatch } from 'react-redux';
import TextCircle from 'components/TextCircle';

export default function StickyContactUs () {
  const { t } = useTranslation('common');
  const maybeTranslate = useTranslatableContent()
  const { region } = useRegionData('contact-us-region', '__');
  const page = usePageData();
  const dispatch = useDispatch();

  const [background, setBackground] = useState(null);
  const [buttonImage, setButtonImage] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [cta, setCta] = useState(null);
  const [links, setLinks] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    setBackground(page?.data?.contact_image_background || region.contact_image_background);
    setButtonImage(page?.data?.contact_image_button?.src && page.data.contact_image_button.src !== ''
      ? page.data.contact_image_button.src
      : region.contact_image_button?.src || placeholder
    );

    let bt = t('button.contact_us');
    if (region.contact_button_text && region.contact_button_text !== '') {
      const text = maybeTranslate(region.contact_button_text);
      if (text && text !== '') {
        bt = text;
      }
    }

    if (page?.data?.contact_button_text && page.data.contact_button_text !== '') {
      const text = maybeTranslate(page.data.contact_button_text);
      if (text && text !== '') {
        bt = text;
      }
    }

    setButtonText(bt);
    setCta(page?.data?.contact_cta || (region.contact_cta || t('button.contact_us')));
    setLinks((page?.data?.contact_links && page.data.contact_links.length) ? page.data.contact_links : region.contact_links);
    setReceiver((page?.data?.show_receiver_field || region.show_receiver_field) ? 'parts' : null);
    setService((page?.data?.datalayer_service || region.datalayer_service) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, region]);

  const toggleModal = () => dispatch(toggleContactModal());

  return <>
    <button className="contact__sticky-button" title={maybeTranslate(cta)} onClick={toggleModal}>
      <TextCircle text={buttonText} />
      <img src={buttonImage} width={72} height={72} alt={buttonText} />
    </button>
    <ContactModal
      image={background}
      links={links}
      receiver={receiver}
      datalayer_service={service}
    />
  </>
}
