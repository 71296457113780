import { useSelector } from 'react-redux'

export default function useTranslatableContent() {
  const {locale} = useSelector(state => state.i18n)

  return field => {
    if (!field || typeof field !== 'object') {
      return field
    }
    if (field[locale] == null) {
      return ''
    }

    return field[locale]
  }
}
