import { Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadInitialContent} from 'store/GlobalContentSlice';
import classNames from "classnames";

import './style/entry.scss';
import {Pages} from './pages';

import ErrorPage from "./pages/ErrorPage";
import { NotFoundPage } from "pages";
import useResetErrorOnRouteChange from 'hooks/useResetErrorOnRouteChange';
// import useCookieConsent from 'hooks/useCookieConsent';

export default function App () {
  const dispatch = useDispatch();
  useResetErrorOnRouteChange();
  // useCookieConsent();

  const { activePage } = useSelector(state => state.globalContent)
  let className = classNames('App', (activePage && 'App_' + activePage));

  const loading = useSelector(state => state.globalContent.loading);
  const error = useSelector(state => state.globalContent.error);

  useEffect(() => {
    dispatch(loadInitialContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Suspense fallback={<div className="loader">Loading...</div>}>
        {error === 'Bad request' && <ErrorPage />}
        {error === 'Not found' && <NotFoundPage />}
        {!error && <Pages/>}
      </Suspense>
      {loading && <div className="loader">Loading...</div>}
    </div>
  );
}
