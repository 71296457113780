import React from 'react';
import ReactDOM from 'react-dom';
import ToastMessage from 'components/ToastMessage';

export default function useToast() {
    return (title, message, type) =>
        ReactDOM.createPortal(
            <ToastMessage key={Math.random()} title={title} type={type}>
                {message}
            </ToastMessage>,
            document.body
        );
}
 