import ReactDOM from "react-dom";
import {CloseButton} from "../index";
import { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const ModalSideView = ({className, isOpen, children, footer, onClickCloseModal}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClickCloseModal();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickCloseModal]);

  return (
    isOpen &&
    ReactDOM.createPortal(
      <motion.div
        ref={modalRef}
        className={`modal-side-view${className ? ` ${className}` : ''}`}
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: '100%', opacity: 0 }}
        transition={{ duration: 0.3, ease: 'linear' }}
      >
        <CloseButton
          className="modal-side-view__close-button"
          onClick={onClickCloseModal}
        />
        <div className="modal-side-view__wrapper">
          {children}
        </div>
        <div className="modal-side-view__white-shadow"></div>
        <div className="modal-side-view__footer">
          {footer}
        </div>
      </motion.div>,
      document.body
    )
  )
};

export default ModalSideView;
