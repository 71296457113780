import React from 'react'
import SubcategoryPreview from "../../components/SubcategoryPreview";

const SubcategoriesPreview = (props) => {
  const {className, content} = props;
  
  const classes = `subcategories-preview-section${className ? ' ' + className : ''}`;
  
  return content && content.length
    ? (
      <section className={classes}>
        <div className="container">
          <div className="subcategories-preview">
            {content.map((el, key) => <React.Fragment key={key}><SubcategoryPreview content={el}/></React.Fragment>)}
          </div>
        </div>
      </section>
    )
    : (
      ''
    );
};

export default SubcategoriesPreview;
