import {NavLink} from "react-router-dom";
import {Image} from "ui";

const SubcategoryPreview = (props) => {
  const {content, key} = props;

  const hasSlug = content && content.name && content.slug;

  const WrapperComponent = hasSlug ? NavLink : 'span';
  const wrapperProps = hasSlug ? {to: content.slug} : {};

  return (
    <WrapperComponent {...wrapperProps} key={key} className="subcategory-preview">
      <Image className="subcategory-preview__image" content={content.image} thumbnailClasses="bg-cover bg-center" />
      <h4 className="subcategory-preview__name">{content.name}</h4>
    </WrapperComponent>
  );
};

export default SubcategoryPreview;
