import React, { useEffect, useState } from 'react';
import { ErrorSvg, CloseSvg } from '../../svg';
import { act } from 'react-dom/test-utils';

export const ToastType = {
    DEFAULT: 'DEFAULT',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

const ToastMessage = (props) => {
    const {
        className,
        title,
        children,
        type = ToastType.DEFAULT,
        hideCloseButton = false,
    } = props;
    const [visible, setVisible] = useState(true);

    const classes = `toast-message${
        className ? ' ' + className : ''
    } toast-message ${visible ? 'visible' : ''} type--${type.toLowerCase()}`;

    useEffect(() => {
        setVisible(true);
        const timeout = setTimeout(() => {
            act(() => {
                setVisible(false);
            });
        }, 10000);

        return () => clearTimeout(timeout);
    }, [title, children]);
    return (
        <div className={classes} data-testid="toast-message">
            <div className="toast-message__content">
                {type === 'ERROR' && (
                    <div className="toast-message__icon">
                        <ErrorSvg />
                    </div>
                )}
                <div className="toast-message__text">
                    <div className="toast-message__title">{title}</div>
                    {children ? (
                        <div className="toast-message__description">
                            {children}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {!hideCloseButton && (
                    <div
                        className="toast-message__close"
                        data-testid="toast-message-close"
                        onClick={() => {
                            setVisible(false);
                        }}
                    >
                        <CloseSvg />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToastMessage;
