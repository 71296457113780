import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const url = !process.env.NODE_ENV || process.env.NODE_ENV === "production"
  ? "https://matkasuvilad.ee"
  : "http://localhost:3000";

i18n
  // .use(HttpBackend)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: 'et',
      fallbackLng: 'et',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        backends: [HttpBackend],
        backendOptions: [
          {
            loadPath: `${url}/i18n/{{lng}}/{{ns}}.json`,
          },
        ],
      },
      // keySeparator: '.',
      ns: ['common', 'accessories', 'campers', 'checkout', 'contact', 'services', 'validation'],
      detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        cache: ['cookie'],
        lookupCookie: 'website#lang',
      },
      react: {
        useSuspense: true,
      },
      debug: true, //process.env.NODE_ENV === 'development',
    },
    (err) => {
      if (err) {
        return console.error('something went wrong loading translations', err);
      }
    }
  );

export default i18n;
