import React from 'react';

// Lazy loading components
const HomePage = React.lazy(() => import('./HomePage'));
const CampersPage = React.lazy(() => import('./CampersPage'));
const CheckoutPage = React.lazy(() => import('./CheckoutPage'));
const CamperPage = React.lazy(() => import('./CamperPage'));
const ContactUsPage = React.lazy(() => import('./ContactUsPage'));
const RentPage = React.lazy(() => import('./RentPage'));
const ServicePage = React.lazy(() => import('./ServicePage'));
const AboutUsPage = React.lazy(() => import('./AboutUsPage'));
const PostsPage = React.lazy(() => import('./PostsPage'));
const PostPage = React.lazy(() => import('./PostPage'));
const AccessoryCategoriesPage = React.lazy(() => import('./AccessoryCategoriesPage'));
const AccessorySubcategoryPage = React.lazy(() => import('./AccessorySubcategoryPage'));
const AccessoryPage = React.lazy(() => import('./AccessoryPage'));
const AccessoryCategoryPage = React.lazy(() => import('./AccessoryCategoryPage'));
const SuccessPage = React.lazy(() => import('./SuccessPage'));
const NotFoundPage = React.lazy(() => import('./NotFoundPage'));
const SearchPage = React.lazy(() => import('./SearchPage'));
const FavouritesPage = React.lazy(() => import('./FavouritesPage'));
const DefaultPage = React.lazy(() => import('./DefaultPage'));
const Pages = React.lazy(() => import('./Pages'));

export {
  HomePage,
  CampersPage,
  CheckoutPage,
  CamperPage,
  ContactUsPage,
  RentPage,
  ServicePage,
  AboutUsPage,
  PostsPage,
  PostPage,
  AccessoryCategoriesPage,
  AccessorySubcategoryPage,
  AccessoryPage,
  AccessoryCategoryPage,
  SuccessPage,
  NotFoundPage,
  SearchPage,
  FavouritesPage,
  DefaultPage,
  Pages,
};
