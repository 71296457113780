import classNames from "classnames";

const ProductCounter = (
  {className, count, setCount, maxCount, maximumProductReached}
) => {
  
  
  const incrementCount = () => {
    if (count >= maxCount) return null
    const newCount = count + 1;
    setCount(newCount);
  };
  
  const decrementCount = () => {
    if (count <= 0) return null
    
    const newCount = count - 1;
    setCount(newCount);
  };
  
  const classes = `product-counter${className ? ' ' + className : ''}`;
  
  const decClassNames = classNames('product-counter__action product-counter__action_dec', (!count && 'product-counter__disabled'));
  const incClassNames = classNames('product-counter__action product-counter__action_inc', (maximumProductReached && 'product-counter__disabled'));
  
  return (
    <div className={classes}>
      <span className={decClassNames} onClick={count ? decrementCount : null}/>
      <span className='product-counter__count'>{count}</span>
      <span className={incClassNames} onClick={!maximumProductReached ? incrementCount : null}/>
    </div>
  );
};

export default ProductCounter;