import { Tabs } from "components";
import classnames from "classnames";

const ProductDetailTabs = (props) => {
  const { className, big } = props;
  const classes = `product-detail-tabs-section${className ? ' ' + className : ''}`;

  const containerClasses = classnames('container', big ? 'container-big' : null)

  return (
    <section className={classes}>
      <div className={containerClasses}>
        <Tabs {...props} className='product-detail-tabs' />
      </div>
    </section>
  );
};

export default ProductDetailTabs;
