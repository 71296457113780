const Line = (props) => {
    const { className } = props;

    const classes = `line${className ? ' ' + className : ''}`;
    
    return (
        <div className={classes}/>
    );
};

export default Line;
