export default function createRoutes(cmsRoutes = [], locales) {
    const routes = [];
    const flatCmsRoutes = [];
    cmsRoutes.forEach((route) => {
        flatCmsRoutes.push(route);

        if (route.children) {
            route.children.forEach((child) => flatCmsRoutes.push(child));
        }
    });

    // Loop through cms pages
    for (const cmsRoute of flatCmsRoutes) {
        // Create separate route for each language
        for (const [locale] of Object.entries(locales)) {
            routes.push({
                id: cmsRoute.id,
                name: cmsRoute.name?.[locale],
                path: cmsRoute.path?.[locale],
                slug: cmsRoute.slug?.[locale],
                template: cmsRoute.template,
                locale,
                localePaths: cmsRoute.path,
            });
        }
    }

    return routes;
}
