import { useSelector } from 'react-redux';

const useRegionData = (regionName, key) => {
  if (!regionName) {
    throw new Error('Region name is required');
  }

  const regions = useSelector((state) => state.globalContent.regions);
  const locale = useSelector((state) => state.i18n.locale);

  let region = {};
  let name = '';
  key = key || locale;

  for (let singleRegion in regions) {
    if (regionName === singleRegion) {
      if (regions[singleRegion][key] !== undefined) {
        region = regions[singleRegion][key];
        name = singleRegion;
      }
    }
  }

  return { region, name, key };
};

export default useRegionData;
