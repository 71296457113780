import { useSelector } from 'react-redux';

const useMenuData = (menuName) => {
    if (!menuName) {
        throw new Error('Menu name is required');
    }
    const {
        globalContent: { menus },
        i18n: { locale },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useSelector((state) => state);

    let menuList = [];
    let name = '';

    for (let menu in menus) {
        if (menuName === menu) {
            if (menus[menu][locale] !== undefined) {
                const currentMenu = menus[menu][locale];
                menuList = currentMenu['menuList'];
                name = currentMenu['name'];
            }
        }
    }

    return { menuList, name, locale };
};

export default useMenuData;
