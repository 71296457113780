import { useEffect, useState } from 'react';

/**
 * Custom React hook to detect if the user is scrolling up.
 * @returns {boolean} Returns true if the user is scrolling up.
 */
const useScrollingUp = () => {
  const [scrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    let prevScroll = window.scrollY; // Initialize previous scroll position

    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (prevScroll > currentScroll) {
        setScrollingUp(true);
      } else {
        setScrollingUp(false);
      }
      prevScroll = currentScroll; // Update previous scroll position
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures effect runs only once

  return scrollingUp;
};

export default useScrollingUp;
