import ReactDOM from 'react-dom';
import { useBodyScrollLock, useOnResize } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { mobileMenuToggle } from 'sections/Header/store/MenuSlice';

const MobileMenu = (props) => {
  const { className, menuList } = props;
  const classes = `mobileMenu${className ? ' ' + className : ''}`;
  const menu = [];
  const navigate = useNavigate();

  const { mobileMenuOpen } = useSelector((store) => store.menu);

  const dispatch = useDispatch();

  useBodyScrollLock(mobileMenuOpen);
  useOnResize();
  if (!menu) return null;

  const getPath = (menuPoint) => {
    return menuPoint.data?.custom_path ? menuPoint.data.custom_path : menuPoint.value.path;
  }

  const hasPath = (item) => {
    return item?.value?.path || item?.data?.custom_path
  }

  const handleMenuClick = (path, event) => {
    dispatch(mobileMenuToggle({ mobileMenuOpen: false }));
    if (event) event.preventDefault();
    navigate(path);
  }

  return (
    mobileMenuOpen &&
    ReactDOM.createPortal(
      <div className={classes}>
        <div className="container">
          <nav className="mobileMenu__wrapper">
            <ul>
              {menuList?.map((point) => (
                <li key={point.id} className="menu__item">
                  {hasPath(point) ? (
                    <NavLink
                      to={getPath(point)}
                      target={point.target}
                      className="menu__link"
                      onClick={(e) => handleMenuClick(getPath(point), e)}
                    >
                      {point.name}
                    </NavLink>
                  ) : (
                    <span className="menu__link">{point.name}</span>
                  )}
                  {point.children ? (
                    <ul className="submenu">
                      {point.children.map((subPoint) => (
                        <li key={subPoint.id} className="submenu__item">
                          {hasPath(subPoint) ? (
                            <NavLink
                              to={getPath(subPoint)}
                              target={subPoint.target}
                              className="menu__link"
                              onClick={(e) => handleMenuClick(getPath(subPoint), e)}
                            >
                              {subPoint.name}
                            </NavLink>
                          ) : (
                            <span className="menu__link">{subPoint.name}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>,
      document.body
    )
  );
};

export default MobileMenu;
