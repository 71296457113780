import React from 'react'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePageData } from 'hooks';
import WhenInContainer from 'components/WhenInContainer';

export default function Breadcrumbs (props) {
  const { locale } = useSelector(state => state.i18n)

  const page = usePageData();

  const breadcrumbs = props?.paths || (page?.breadcrumbs || null);

  return (breadcrumbs && breadcrumbs.length > 0) && (
    <WhenInContainer container={props?.withContainer} className="mt-12 -mb-9">
      <div className={`breadcrumbs${props?.className ? ` ${props.className}` : ''}`}>
        <div className="breadcrumbs">
          {breadcrumbs.map((path, key) =>
            path.to
              ? <React.Fragment key={key}>
                <NavLink
                  to={typeof path.to === 'string' ? path.to : path.to[locale]}
                >
                  {path.name}
                </NavLink>
                {' / '}
              </React.Fragment>
              : <span key={key}>{path.name}</span>
          )}
        </div>
      </div>
    </WhenInContainer>
  );
};
