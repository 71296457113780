import { useEffect, useState } from 'react';
import usePageData from './usePageData';
import TagManager from 'react-gtm-module';
import { debounce } from 'lodash';

export default function usePageView () {
  const [previousPage, setPreviousPage] = useState(null);
  const page = usePageData();

  const title = page?.seo?.title || page?.name || window.title
  const url = window.location.href;

  const sendPageView = debounce(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page: {
          title: title,
          url: url,
        }
      },
    });
    setPreviousPage(url);
  }, 500);

  useEffect(() => {
    if (!title || previousPage === url) return;
    sendPageView();

    return () => sendPageView.cancel();
  }, [title, previousPage, sendPageView, url]);
}
