import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearErrors } from 'store/GlobalContentSlice';

export default function useResetErrorOnRouteChange () {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(clearErrors());
  }, [pathname, dispatch]);
}
