import { useRef } from 'react';
import Dropdown from 'components/Fields/Dropdown';
import { usePathname } from '../../hooks';
import { useDispatch } from 'react-redux';
import { loadPageContent } from '../../store/GlobalContentSlice';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Sort = () => {
  const pathname = usePathname();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('campers');
  const ref = useRef(null);
  const options = [
    {
      text: t('option.sort_by_name'),
      value: 'name',
    },
    {
      text: t('option.sort_by_popularity'),
      value: 'popularity',
    },
    {
      text: t('option.sort_by_price_low_high'), //'Price: Low to High',
      value: 'price',
    },
    {
      text: t('option.sort_by_price_high_low'), //'Price: High to Low',
      value: 'price_desc',
    },
    {
      text: t('option.sort_by_newest'), // 'Newest',
      value: 'latest',
    },
    {
      text: t('option.sort_by_delivery_time'),
      value: 'delivery',
    },
    {
      text: t('option.sort_by_availability'),
      value: 'availability',
    },
  ];

  const updateSearchParams = (k) => {
    const params = new URLSearchParams(searchParams);
    params.set('sort', k);
    dispatch(loadPageContent({ path: `${pathname}?${params.toString()}` }));
    setSearchParams(params);
  };

  return (
    <div ref={ref}>
      <Dropdown
        label={t('field.sort')}
        options={options}
        selected={searchParams.get('sort') ?? null}
        setSelected={(k) => updateSearchParams(k)}
      />
    </div>
  );
};

export default Sort;
