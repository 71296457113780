// eslint-disable-next-line import/no-anonymous-default-export
export default {
  '/news/1': '/news',
  '/uudised/1': '/uudised',
  
  '/accessory_category/:slug/1': '/accessory_category/:slug',
  '/tarviku_kategooria/:slug/1': '/tarviku_kategooria/:slug',

  '/tarviku_alamkategooria/:slug/1': '/tarviku_alamkategooria/:slug',
  '/accessory_subcategory/:slug/1': '/accessory_subcategory/:slug'
};