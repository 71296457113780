import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setActivePage} from '../store/GlobalContentSlice';
import {matchRoute} from "helpers";
import {usePathname} from "./index";


const useSetActivePage = () => {
  const { routes } = useSelector((state) => state.globalContent);
  const pathname = usePathname();
  const dispatch = useDispatch();

  useEffect(() => {
    const matchedRoute = matchRoute(routes, pathname);

    if (matchedRoute) {
      const templateName = matchedRoute.template;
      dispatch(setActivePage(templateName));
    }
  }, [routes, dispatch, pathname]);
};

export default useSetActivePage;


