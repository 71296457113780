import { useState } from "react"
import PropTypes from "prop-types"

export default function Dropdown (props) {
  const [isActive, setIsActive] = useState(false)
  const label = props?.options?.find(option => option.value === props.selected)?.text || props.label
  return (
    <div className={`dropdown${props.className ? ' ' + props.className : ''}`}>
      <button type="button" className="dropdown__button" onClick={e => setIsActive(!isActive)} aria-expanded={isActive}>
        <span>{label}</span>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 3L5 7L9 3" stroke="#0E1F37" strokeLinejoin="bevel" />
        </svg>
      </button>
      {isActive && (
        <div className="dropdown__content">
          <ul>
            {props?.options?.map(option => (
              <li
                className="dropdown__item"
                key={option.value}
                onClick={e => {
                  props.setSelected(option.value)
                  setIsActive(false)
                }}
              >
                {option.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

Dropdown.prototype = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
}
