import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui';
import FeedbackForm from 'sections/Feedback/components/FeedbackForm';
import { useTranslatableContent } from 'hooks';
import MediaQuery from 'react-responsive';
import { toggleContactModal } from 'store/GlobalContentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'ui';

export default function ContactModal (props) {
  const { t } = useTranslation('services');
  const maybeTranslate = useTranslatableContent()
  const dispatch = useDispatch();

  const [links, setLinks] = useState([])
  const isOpen = useSelector((state) => state.globalContent.contactModalOpen);
  const contacts = useSelector((state) => state.globalContent.contactModalContacts);

  useEffect(() => {
    if (contacts) {
      const l = [];
      if (contacts.email) {
        l.push({
          href: `mailto:${contacts.email}`,
          text: contacts.email,
        });
      }
      if (contacts.phone) {
        l.push({
          href: `tel:${contacts.phone}`,
          text: contacts.phone,
        });
      }
      setLinks(l);
    } else if (props.links) {
      setLinks(props.links.map(link => {
        return {
          href: link.attributes?.url,
          text: link.attributes?.label,
          target: link.attributes?.target
        };
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, props.links]);

  const toggleModal = () => dispatch(toggleContactModal());

  const datalayer = {
    service: props?.datalayer_service || (contacts?.datalayer_service || null)
  }

  return <Modal show={isOpen} close={toggleModal}>
    <div className="camper-detailed-information__modal-content">
      <h2 className="camper-detailed-information__modal-title">
        {t('heading.contact')}
      </h2>

      {links && <MediaQuery maxWidth={1023}>
        <div className="contacts-in-picture__information mb-6">
          {props.title && <h4 className="contacts-in-picture__title">{maybeTranslate(props.title)}</h4>}
          {links.map((link, index) =>
            <a key={index} href={link.href} className="contacts-in-picture__link">
              {maybeTranslate(link.text)}
            </a>
          )}
        </div>
      </MediaQuery>}

      <div className="lg:grid grid-cols-2 lg:gap-17">
        <MediaQuery minWidth={1024}>
          <div>
            {links && (
              <div className="contacts-in-picture__information w-full mb-4">
                {props.title && <h4 className="contacts-in-picture__title">{maybeTranslate(props.title)}</h4>}
                {links.map((link, index) =>
                  <a key={index} href={link.href} className="contacts-in-picture__link">
                    {maybeTranslate(link.text)}
                  </a>
                )}
              </div>
            )}
            {props.image && (
              <div className="contacts-in-picture">
                <Image className="contacts-in-picture__image" content={props.image} />
              </div>
            )}
          </div>
        </MediaQuery>
        <div>
          <FeedbackForm receiver={props.receiver} to={contacts?.to || null} datalayer={datalayer} />
        </div>
      </div>
    </div>
  </Modal>
};

