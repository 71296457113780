import {
  HomePage,
  CampersPage,
  CamperPage,
  ContactUsPage,
  RentPage,
  ServicePage,
  AboutUsPage,
  PostsPage,
  PostPage,
  AccessoryCategoriesPage,
  AccessoryCategoryPage,
  AccessorySubcategoryPage,
  CheckoutPage,
  AccessoryPage,
  SuccessPage,
  SearchPage,
  FavouritesPage,
  DefaultPage
} from "pages";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "home-page": {
    component: HomePage,
  },
  "campers-page": {
    component: CampersPage,
  },
  "camper-page": {
    component: CamperPage,
  },
  "contact-page": {
    component: ContactUsPage,
  },
  "rent-page": {
    component: RentPage,
  },
  "service-page": {
    component: ServicePage,
  },
  "about-us-page": {
    component: AboutUsPage,
  },
  "posts-page": {
    component: PostsPage,
  },
  "_posts-page": {
    component: PostsPage,
  },
  "post-page": {
    component: PostPage,
  },
  "search-page": {
    component: SearchPage,
  },
  "accessory-categories-page": {
    component: AccessoryCategoriesPage,
  },
  "_accessory-categories-page": {
    component: AccessoryCategoriesPage,
  },
  "accessory-category-page": {
    component: AccessoryCategoryPage,
  },
  "_accessory-category-page": {
    component: AccessoryCategoryPage,
  },
  "accessory-subcategory-page": {
    component: AccessorySubcategoryPage,
  },
  "_accessory-subcategory-page": {
    component: AccessorySubcategoryPage,
  },
  "accessory-page": {
    component: AccessoryPage,
  },
  "_accessory-page": {
    component: AccessoryPage,
  },
  "checkout-page": {
    component: CheckoutPage,
  },
  "success-page": {
    component: SuccessPage,
  },
  "favourites": {
    component: FavouritesPage
  },
  "page": {
    component: DefaultPage,
  }
};
