import { CircleBigArrowSvg, BlackBigArrowSvg } from '../../svg';
import { NavLink, useNavigate } from 'react-router-dom';

function Button(props) {
  const {
    text,
    disabled,
    className,
    onClick,
    type = 'button',
    styleType = 'red',
    to = null,
  } = props;

  const history = useNavigate();

  const classes = `button${className ? ' ' + className : ''}${' ' + styleType}`;
  const ButtonComponent = to ? NavLink : 'button';
  const buttonProps = {
    disabled: disabled,
    className: classes,
    onClick: (!to || (to && onClick)) ? onClick : null,
    type: type,
    children: (
      <>
        {text && <span>{text}</span>}
        {styleType === 'red' && <CircleBigArrowSvg colorArrow={props?.arrowColor} />}
        {styleType === 'transparent' && (
          <CircleBigArrowSvg
            colorArrow={props?.arrowColor || "#ffffff"}
            colorFillCircle="transparent"
          />
        )}
        {styleType === 'white' && <BlackBigArrowSvg />}
      </>
    ),
  };

  if (to && onClick) {
    const handleClick = () => {
      onClick();
      history.push(to);
    };
    return <ButtonComponent to={to} onClick={handleClick} {...buttonProps} />;
  }

  if (to) {
    return <ButtonComponent to={to} {...buttonProps} />;
  }
  return <ButtonComponent {...buttonProps} />;
}

export default Button;
