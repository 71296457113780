import {useEffect, useState} from "react";
import {MagnifierSvg} from "../../../../svg";
import {useSearch} from "hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import {useSelector} from "react-redux";

const Search = ({className, onMobileMenuToggle}) => {
  const classes = `search${className ? " " + className : ""}`;
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const {locale} = useSelector((state) => state.i18n);
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { getSearchUrl } = useSearch();

  const doSearch = () => {
    // let scopes = "";

    if (searchQuery === "") {
      setShowSearch(false);
      return;
    }

    if (searchQuery === "") {
      return;
    }

    if (searchQuery === params.get("query")) {
      return;
    }

    setShowSearch(false);

    navigate(getSearchUrl(searchQuery));
  };

  const maybeEnterSearch = (e) => {
    if (e.key === "Enter") {
      doSearch();
    }
  };

  useEffect(() => {
    if (params.get("query")) {
      setShowSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`search ${classes}`}>
        <div
          className="search__input-wrapper"
          aria-expanded={showSearch}
        >
          <input
            id="search"
            autoFocus
            type="text"
            className={`search__input${showSearch ? ' search__input--visible' : ''}`}
            placeholder={t("field.search")}
            defaultValue={params.get("query")}
            onKeyDown={(e) => maybeEnterSearch(e)}
            onInput={(e) => setSearchQuery(e.target?.value)}
          />
          <label
            htmlFor="search"
            className="search__button"
            onClick={() => setShowSearch(state => !state)}
          >
            <MagnifierSvg />
          </label>
        </div>
        <label
          htmlFor="mobile-search"
          className="search__button search__button--mobile"
          onClick={onMobileMenuToggle}
        >
          <MagnifierSvg/>
        </label>
      </div>
    </>
  );
};

export default Search;
