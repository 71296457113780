import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createRoutes } from 'helpers';
import { fetchInitialContent, fetchPageData } from 'api';
import {setLocale, setTranslatedStrings} from './I18nSlice';
import {redirectPaths} from "config";

// Define async thunk to load initial content
export const loadInitialContent = createAsyncThunk(
  'globalContent/loadInitialContent',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchInitialContent();
      if (!response.ok) {
        throw new Error('Initial Server Error!');
      }
      const data = await response.json();
      dispatch(setTranslatedStrings(data.translated_strings));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define async thunk to load page content
export const loadPageContent = createAsyncThunk(
  'globalContent/loadPageContent',
  async ({ path, params }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchPageData(path.replace('?', '&'), params);

      if (response?.status === 404) {
        return rejectWithValue('Not found');
      }

      if (!response.ok) {
        return rejectWithValue('Bad request');
      }

      const data = await response.json();

      if (data.error_code && data.error_code === 404) {
        return rejectWithValue('Not found');
      }

      dispatch(setLocale(data.locale));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define the initial state of the global content slice
const initialState = {
  contactModalOpen: false,
  contactModalContacts: null,
  shopColumns: 3,
  seo: {},
  routes: [],
  singlePages: [],
  pages: {},
  menus: [],
  locales: {},
  regions: [],
  cookiesFields: {},
  loading: false,
  error: false,
  initial: false,
  activePage: null,
  redirectPaths: redirectPaths,
  perPage: 25,
};

// Define the global content slice
export const globalContentSlice = createSlice({
  name: 'globalContent',
  initialState,
  reducers: {
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setShopColumns: (state, action) => {
      state.shopColumns = action.payload;
    },
    setState: (state, action) => {
      Object.assign(state, action.payload);
    },
    toggleContactModal: (state, action) => {
      state.contactModalOpen = !state.contactModalOpen;
      if (state.contactModalOpen) {
        state.contactModalContacts = action.payload || null;
      } else {
        state.contactModalContacts = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadInitialContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadInitialContent.fulfilled, (state, action) => {
        state.regions = action.payload.regions;
        state.singlePages = action.payload.single_pages;
        state.menus = action.payload.menus;
        state.locales = action.payload.locales;
        state.routes = createRoutes(
          action.payload.routes || [],
          action.payload.locales
        );
        state.initial = true;
        state.loading = false;
      })
      .addCase(loadInitialContent.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })


      .addCase(loadPageContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadPageContent.fulfilled, (state, action) => {
        state.error = null;
        const page = action.payload;
        state.pages[page.path] = page;
        state.loading = false;
      })
      .addCase(loadPageContent.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// Export the global content slice reducer and actions
export const { setShopColumns, setState, toggleContactModal } = globalContentSlice.actions;
export default globalContentSlice.reducer;

// Define action creators to set routes and pages
export const setRoutes = (routes) => {
  return setState({ routes });
};

export const setPage = (page) => {
  const { slug } = page;
  return setState({ pages: { [slug]: page } });
};

export const setActivePage = (activePage) => async (dispatch) => {
  dispatch(setState({ activePage: activePage }));
};

export const clearErrors = () => (dispatch) => {
  dispatch(setState({ error: null }));
};
