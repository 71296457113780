const getNextLocale = (page) => {
  if (!page?.localePaths) return { locale: 'et', path: '/'};

  const forCurrentPage = Object.entries(page.localePaths)
    .map(([locale, path]) => {
      return { locale, path };
    })
    .filter((x) => {
      return x.path !== page.path;
    })[0];
  //TODO: Добавить переключение на главную страницу, если нету страницы с переводом
  // TODO: translate comment
  if (forCurrentPage) return forCurrentPage;

  return {
    locale: page.locale === 'et' ? 'en' : 'et',
    path: page.locale === 'et' ? '/en' : '/',
  };
};

export default getNextLocale
