import {CartSvg} from 'svg'
import {useSelector, useDispatch} from "react-redux";
import {setCartIsOpen} from "store/CartSlice";
import {RedQuantity} from "ui";

const CartButton = (props) => {
  const {className} = props;
  const dispatch = useDispatch()
  const {totalQuantity, cartIsOpen} = useSelector(state => state.cart)
  
  const classes = `cart-button${className ? ' ' + className : ''}`;
  
  return (<span className={classes} onClick={() => dispatch(setCartIsOpen(!cartIsOpen))}>
    {totalQuantity ? <RedQuantity quantity={totalQuantity}/> : null}
    <CartSvg/>
  </span>);
};

export default CartButton;
