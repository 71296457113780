import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mobileMenuToggle } from '../sections/Header/store/MenuSlice';

const useOnResize = () => {
  const dispatch = useDispatch();
  const { mobileMenuOpen } = useSelector((store) => store.menu);

  const handleResize = useCallback(() => {
    if (mobileMenuOpen) {
      dispatch(mobileMenuToggle({ mobileMenuOpen: false }));
    }
  }, [dispatch, mobileMenuOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileMenuOpen, dispatch, handleResize]);
};

export default useOnResize;