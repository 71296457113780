import { Image } from 'ui';

const Partners = (props) => {
    const { className, title, list } = props;

    const classes = `partners${className ? ' ' + className : ''}`;

    if (!list || !list.length) {
        return null;
    }
    
    return (
        <div className={classes}>
            {title && (
                <h6 className="partners__title footer-col-title">{title}</h6>
            )}
            {list.length ? (
                <ul className="partners__list">
                    {list.map((partner, index) => (
                        <li key={index} className="partners__point">
                            <Image
                                className="partners__image"
                                content={partner}
                            />
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

export default Partners;
