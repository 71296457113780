import { ContactsInPicture } from 'components';
import { FeedbackForm } from './components';
import { MatkaSuviladSvg } from 'svg';
import { useSelector } from "react-redux";
import MediaQuery from 'react-responsive';

const Feedback = (props) => {
  const { className, title, contactData, index } = props;
  const i18n = useSelector(state => state.i18n)

  const classes = `feedback-section${className ? ' ' + className : ''}`;

  return (
    <section className={classes} key={index}>
      <div className="container">
        <div className="feedback">
          <div className="feedback__left-col lg:mr-5">
            {title && <h2 className="feedback__title mb-4 md:mb-6 lg:mb-8">{title}</h2>}

            {contactData && <MediaQuery maxWidth={1023}>
              <div className="contacts-in-picture__information mb-6">
                {contactData.title && <h4 className="contacts-in-picture__title">{contactData.title}</h4>}
                {contactData?.links.map((link, index) =>
                  <a key={index} href={link.href} className="contacts-in-picture__link">
                    {link.text}
                  </a>
                )}
              </div>
            </MediaQuery>}

            <FeedbackForm
              className="feedback__feedback-form"
              locale={i18n.locale}
            />
          </div>

          <MediaQuery minWidth={1024}>
            <div className="feedback__right-col">
              {contactData && <ContactsInPicture
                title={contactData.title ? contactData.title : ''}
                image={contactData.image ? contactData.image : ''}
                links={contactData.links ? contactData.links : ''}
              />}
            </div>
          </MediaQuery>

          <MediaQuery minWidth={1024}>
            <MatkaSuviladSvg className="feedback-section__matka-suvilad-svg hidden lg:block ml-8" />
          </MediaQuery>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
