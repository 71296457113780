export default function TextCircle ({ text }) {
  let size = '0.75rem';

  if (text && text.length > 18) {
    size = '0.55rem';
  } else if (text && text.length > 14) {
    size = '0.65rem';
  }

  return (
    <svg viewBox="0 0 150 150">
      <defs>
        <path
          id="circle"
          fill="none"
          stroke="white"
          d="M 134 75
            A 50 50 0 1 1 16 75
            A 50 50 0 1 1 134 75
            A 50 50 0 1 1 16 75
            "
        />
      </defs>
      <circle cx="96" cy="16" r="2.5" fill="currentColor" />
      <circle cx="117" cy="122" r="2.5" fill="currentColor" />
      <circle cx="14" cy="88" r="2.5" fill="currentColor" />
      <text fill="currentColor" fontSize={size}>
        <textPath xlinkHref="#circle" textAnchor="middle" startOffset="42.5%">
          {text}
        </textPath>
        <textPath xlinkHref="#circle" textAnchor="middle" startOffset="64.75%">
          {text}
        </textPath>
        <textPath xlinkHref="#circle" textAnchor="middle" startOffset="86.7%">
          {text}
        </textPath>
      </text>
    </svg>
  )
}
