// import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const useSearch = () => {
  const {locale} = useSelector((state) => state.i18n);

  // const params = new URLSearchParams(window.location.search);

  const url = new URL(window.location);
  const searchUrl = url;

  // const navigate = useNavigate();

  function setLimitScope() {
    if (url.pathname.includes("accessory") || url.pathname.includes("accessories") || url.pathname.includes("tarvik") || url.pathname.includes("tarvikud")) {
      return searchUrl.searchParams.set("scopes", "accessories");
    }

    if (url.pathname.includes("camper") || url.pathname.includes("campers") || url.pathname.includes("matkaauto") || url.pathname.includes("matkaautod")) {
      return searchUrl.searchParams.set("scopes", "campers");
    }

    if (url.pathname.includes("single-news") || url.pathname.includes("uudised") || url.pathname.includes("uudis") || url.pathname.includes("news")) {
      return searchUrl.searchParams.set("scopes", "posts");
    }

    if (url.searchParams.has("scopes")) {
      return searchUrl.searchParams.set("scopes", url.searchParams.get("scopes"));
    }
  }

  function getSearchUrl(query) {
    setLimitScope();

    searchUrl.pathname = `/${locale === "et" ? "otsi" : "search"}`;
    searchUrl.searchParams.set("query", query);

    return searchUrl.pathname + searchUrl.search;
  }

  return {
    getSearchUrl
  };
}

export default useSearch;
