import React from 'react';

const Socials = React.lazy(() => import('./Socials'));
const Tabs = React.lazy(() => import('./Tabs'));
const ToastMessage = React.lazy(() => import('./ToastMessage'));
const ContactsInPicture = React.lazy(() => import('./ContactsInPicture'));
const PostPreview = React.lazy(() => import('./PostPreview'));
const Article = React.lazy(() => import('./Article'));
const CamperSeatsBeds = React.lazy(() => import('./CamperSeatsBeds'));
const ProductPreview = React.lazy(() => import('./ProductPreview'));
const PreviewPrice = React.lazy(() => import('./PreviewPrice'));
const PreviewCategories = React.lazy(() => import('./PreviewCategories'));
const PreviewTitle = React.lazy(() => import('./PreviewTitle'));
const CamperPreview = React.lazy(() => import('./CamperPreview'));
const SubcategoryPreview = React.lazy(() => import('./SubcategoryPreview'));
const DetailedInformationSlider = React.lazy(() => import('./DetailedInformationSlider'));
const ProductGallery = React.lazy(() => import('./ProductGallery'));
const ProductPrice = React.lazy(() => import('./ProductPrice'));
const ProductName = React.lazy(() => import('./ProductName'));
const WUSWUG = React.lazy(() => import('./WUSWUG'));
const ProductAddToCart = React.lazy(() => import('./ProductAddToCart'));
const Cart = React.lazy(() => import('./Cart'));
const CartProductPreview = React.lazy(() => import('./CartProductPreview'));

export {
  Socials,
  Tabs,
  ToastMessage,
  ContactsInPicture,
  PostPreview,
  Article,
  CamperSeatsBeds,
  ProductPreview,
  PreviewPrice,
  PreviewCategories,
  PreviewTitle,
  CamperPreview,
  SubcategoryPreview,
  DetailedInformationSlider,
  ProductGallery,
  ProductPrice,
  ProductName,
  WUSWUG,
  ProductAddToCart,
  Cart,
  CartProductPreview,
};
