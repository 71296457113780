import { useCallback } from 'react';
import { usePageData } from 'hooks';
import TagManager from 'react-gtm-module';

export default function useGenerateLead () {
  const { data, path, template } = usePageData();

  const handleGenerateLead = useCallback((leadData) => {
    const { id, name, email, service, subject } = leadData;

    console.log('%c~Page data:', 'color: orange', data);

    const additional = {}
    if (template === 'camper-page') {
      const product = data?.content || null
      additional.product = {
        id: product?.id || null,
        name: product?.name || null,
        price: parseFloat(product?.sale_price && product?.sale_price > 0 ? product?.sale_price : product?.price) || null,
        category: 'Matkasuvilad',
      }
    } else if (template === 'accessory-page') {
      const product = data?.content?.find(section => section.layout === 'details')?.attributes || null
      additional.product = {
        id: product?.id,
        name: product?.name,
        price: product?.price,
        category: product?.categories?.[0]?.name || null,
      }
    }

    if (!id || !name || !email) return;

    TagManager.dataLayer({
      dataLayer: {
        event: 'generate_lead',
        lead: {
          lead_id: id,
          name: name,
          email: email,
          service: service || null,
          subject: subject || null,
          path: path || null,
          ...additional
        },
      },
    });
  }, [data, path, template]);

  return handleGenerateLead;
}
