
/**
 * @returns path with express style dynamic slugs resolved according to the given params
 */
export default function getCompilePath(path, params) {
    let output = path;
    Object.entries(params).forEach(([slug, value]) => {
        output = output.replace(`:${slug}`, value);
    });

    return output;
}
