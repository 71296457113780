import { useMemo } from 'react';
import { getCompilePath } from 'helpers';
import { matchPath } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { usePathname } from 'hooks';

export default function usePageData () {
  const globalContent = useSelector(state => state.globalContent, shallowEqual);
  const pathname = usePathname();

  const page = useMemo(() => {
    const route = getRoute(globalContent, pathname);
    const pageData = globalContent.pages[pathname] || {};
    return { ...route, ...pageData };
  }, [globalContent, pathname]);

  return page;
}

function getRoute (globalContent, pathname) {
  const { routes, pages, singlePages } = globalContent;
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      const localePaths = Object.entries(route.localePaths).reduce((acc, [locale, path]) => {
        const slugs = getSlugsFromPath(route.path, pathname);
        const newPath = composePath(path, slugs);
        if (singlePages.includes(route.template)) {
          const defaultSlug = pages[pathname]?.data?.slug;
          const postSlug = pages[pathname]?.slugs?.[locale];
          match.params.slug = defaultSlug ?? postSlug;
        }
        acc[locale] = newPath;
        return acc;
      }, {});

      return {
        ...route,
        path: getCompilePath(route.path, match.params),
        localePaths,
      };
    }
  }
  return null;
}

function getSlugsFromPath (path, pathname) {
  return path.split('/').map((part, i) => part.startsWith(':') ? pathname.split('/')[i].replace(':', '') : null).filter(Boolean);
}

function composePath (path, slugs) {
  let partCount = 0;
  return path.split('/').map(part => part.startsWith(':') ? `/${slugs[partCount++]}` : `/${part}`).join('').substring(1);
}
