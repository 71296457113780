export default function handleRedirect(redirectPaths, currentPathName, navigate) {
  const currentPathnameArray = currentPathName.split('/').filter(Boolean);
  const redirectPathsArr = Object.keys(redirectPaths);

  let redirectKey = null;

  for (let i = 0; i < redirectPathsArr.length; i++) {
    const pathArray = redirectPathsArr[i].split('/').filter(Boolean);

    if (currentPathnameArray.length !== pathArray.length) {
      continue;
    }

    let matches = true;

    for (let j = 0; j < currentPathnameArray.length; j++) {
      if (pathArray[j].startsWith(':')) {
        continue;
      }

      if (currentPathnameArray[j] !== pathArray[j]) {
        matches = false;
        break;
      }
    }

    if (matches) {
      redirectKey = redirectPathsArr[i];
      break;
    }
  }

  if (redirectKey) {
    let redirectPath = redirectPaths[redirectKey];

    redirectPath.split('/').forEach((segment, index) => {
      if (segment.startsWith(':') && currentPathnameArray[index - 1]) {
        redirectPath = redirectPath.replace(segment, currentPathnameArray[index - 1]);
      }
    });

    navigate(redirectPath);
    return true;
  }

  return false;
}
