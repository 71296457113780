import React, { useState, useEffect } from 'react';

const FeatureBox = (props) => {
  const {
    className,
    features = [],
    colorMode = 'light', // По умолчанию light mode
    isHoverEffect = false,
    onClick,
  } = props;

  const [currentMode, setCurrentMode] = useState(colorMode);
  const [isHover, setIsHover] = useState(isHoverEffect);
  const modeColors = {
    dark: '#fff',
    light: '#0e1f37',
    red: '#E70000'
  };

  useEffect(() => {
    setCurrentMode(colorMode);
  }, [colorMode]);

  const onClickHandler = () => {
    if (typeof onClick === 'function') {
      setIsHover(!isHover);
      setCurrentMode(currentMode === 'dark' ? 'light' : 'dark');
    }
  };

  const classes = `feature-box
    ${className ? ' ' + className : ''}
    ${currentMode}`;

  return (
    <ul
      className={classes}
      onMouseEnter={() => isHover && setCurrentMode('dark')}
      onMouseLeave={() => isHover && setCurrentMode(colorMode)}
      onClick={onClickHandler}
    >
      {features.length &&
        features.map((feature, index) => {
          feature.icon = feature.icon && React.cloneElement(feature.icon, {
            ...feature.icon.props,
            color: currentMode === 'dark' ? modeColors['dark'] : modeColors['light'],
            // color: modeColors[currentMode],
          });

          return (
            <li key={index} className="feature-box__detail">
              {feature.icon && feature.icon}
              {feature.text && (
                <span className="feature-box__label">
                  {feature.text}
                </span>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default FeatureBox;
