import React, { useEffect, useState } from "react";
import { ProductPreview } from "components";
import { useTranslation } from "react-i18next";
import Slider from "components/Slider";

const AccessoryPreviewSlider = ({ className, accessories, title }) => {
  const { t } = useTranslation();
  const classes = `accessory-preview-slider-section${className ? " " + className : ""}`;

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const slides = accessories.map(product => product && (
      <ProductPreview content={product} />
    ));
    setSlides(slides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={classes}>
      <div className="container">
        <div className="accessory-preview-slider">
          <h1 className="accessory-preview-slider__title">
            {t('heading.you_might_also_like', { ns: 'accessories' })}
          </h1>

          <div className="accessory-preview-slider__list">
            <Slider slides={slides} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccessoryPreviewSlider;
