export default function findMatchingRedirectPath(redirectPaths, currentPath = null) {
  if (!currentPath) return null;

  const currentPathArray = currentPath.split('/').filter(el => el !== '');

  // for (const redirectPathValue of Object.values(redirectPaths)) {
  for (const [redirectPathKey, redirectPathValue] of Object.entries(redirectPaths)) {
    const redirectPathArray = redirectPathValue.split('/').filter(el => el !== '');

    if (currentPathArray.length !== redirectPathArray.length) continue;

    const pathForRedirect = redirectPathArray.map((part, j) =>
      part.startsWith(':') ? currentPathArray[j] : part
    );

    // return '/' + pathForRedirect.join('/');

    if (pathForRedirect.every((part, j) => part === currentPathArray[j])) {
      const redirectPathKeyArray = redirectPathKey.split('/').filter(el => el !== '');
      pathForRedirect.push(redirectPathKeyArray.pop());
      return '/' + pathForRedirect.join('/');
    }
  }

  return null;
}
