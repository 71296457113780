import {configureStore} from "@reduxjs/toolkit";
import globalContent from "./GlobalContentSlice";
import i18n from "./I18nSlice";
import menuReducer from "../sections/Header/store/MenuSlice";
import campers from "./CampersSlice";
import cart from "./CartSlice";
import favouritesSlice from "./favouritesSlice";


const store = configureStore({
  reducer: {
    menu: menuReducer,
    i18n: i18n,
    cart: cart,
    globalContent: globalContent,
    campers: campers,
    favourites: favouritesSlice
  },
});

export default store;
