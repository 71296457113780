import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CloseButton (props) {
  const { t } = useTranslation('common');

  return (
    <div
      className={`close-button${props.className ? ` ${props.className}` : ''}`}
      onClick={props.onClick}
    >
      <div className="close-button__inner">
        <label className="close-button__label">{props?.label || t('button.close')}</label>
      </div>
    </div>
  );
};
