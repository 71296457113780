import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'components/Layouts/DefaultLayout'

export default function ErrorPage () {
  const { t } = useTranslation('common');

  return (
    <DefaultLayout>
      <div className="error-page">
        <div>
          <h1 className="mb-16">{t('heading.something_went_wrong')}</h1>
          <p className="text-center mb-2">{t('text.try_to_reload_or_return_to_home')} <a href="/" className="underline">{t('button.to_home')}</a>.</p>
          <p className="text-center">{t('text.notify_us_about_error')}</p>
        </div>
      </div>
    </DefaultLayout>
  );
}
