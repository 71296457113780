import {LogoSvg157x32} from 'svg'
import {usePathFromTemplate} from "../../hooks";
import {NavLink} from "react-router-dom";

const Logo = ({className}) => {
    const homePagePath = usePathFromTemplate('home-page')

    const classes = `logo${className ? ' ' + className : ''}`;
    
    return <NavLink to={homePagePath}><LogoSvg157x32 className={classes}/></NavLink>;
};

export default Logo;
