import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as CookieConsent from 'vanilla-cookieconsent';

export default function FooterColumn (props) {
  const { t } = useTranslation('common');

  const openCookiePreferences = () => CookieConsent.showPreferences();

  return <div className={`footer-column${props.className ? ` ${props.className}` : ''}`}>
    {props.title && <div className="footer-column__title">{props.title}</div>}
    {props.items && <ul className={`footer-column__list${props.smallGap ? ' footer-column__list--tight' : ''}`}>
      {props.items.map(item => <li key={item.key} className="footer-column__list-item">
        {item.attributes.caption && <span className="footer-column__list-item-caption">{item.attributes.caption}</span>}
        {item.attributes.show_cookie_preferences
          ? <button className="btn--edit-cookies" type="button" data-cc="show-preferencesModal" onClick={openCookiePreferences}>
            {t('button.manage_cookies')}
          </button>
          : item.attributes.url
            ? <NavLink to={item.attributes.url} className="footer-column__list-item-link">{item.attributes.text}</NavLink>
            : <span className="footer-column__list-item-title">{item.attributes.text}</span>
        }
      </li>)}
    </ul>}
  </div>
}
