import { useSearch } from "hooks";
import { useState } from "react";
// import {useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileSearch = () => {
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation('common');
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { getSearchUrl } = useSearch();

  function handleDoSearch (event) {
    event.preventDefault();

    if (searchQuery === "") {
      return;
    }

    if (searchQuery === params.get("query")) {
      return;
    }

    navigate(getSearchUrl(searchQuery));
  }

  return (
    <form className="mobile-search" onSubmit={handleDoSearch}>
      <input
        type="text"
        id="mobile-search"
        className="search__input"
        placeholder={t("field.search")}
        defaultValue={params.get("query")}
        onInput={(e) => setSearchQuery(e.target?.value)}
      />
    </form>
  )
}

export default MobileSearch;
