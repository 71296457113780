import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'ui';

export default function ReadMoreSlide (props) {
  return <NavLink to={props.to} className={`slide--read-more${props.className ? ` props.className` : ''}`}>
    <Image content={props.image} />
    <div className="slide__content">
      {props.text && props.to && <div className="slide__button">
        {props.text}
        <span>
          <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7.5H22M22 7.5L14.9787 1M22 7.5L14.9787 14" stroke="currentColor" strokeLinejoin="bevel" />
          </svg>
        </span>
      </div>}
    </div>
  </NavLink>
}
