import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPageContent } from 'store/GlobalContentSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleRedirect } from "helpers";
import { usePathname } from "hooks";

const useContentLoader = () => {
  const dispatch = useDispatch();
  const { loading, error, initial, pages, redirectPaths } = useSelector((state) => state.globalContent);
  const { pathname: currentPathname, search } = useLocation();
  let requestPathname = usePathname();
  const navigate = useNavigate();
  if (!requestPathname.startsWith("/")) {
    requestPathname = "/" + requestPathname;
  }

  const searchParams = new URLSearchParams(search);
  const queryParams = {};
  for (const [key, value] of searchParams) {
    queryParams[key] = value;
  }

  useEffect(() => {
    handleRedirect(redirectPaths, currentPathname, navigate);
    if (initial && !pages[requestPathname]) {
      dispatch(loadPageContent({ path: requestPathname, params: queryParams }));
    }

    window.scrollTo({
      top: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initial, requestPathname, pages]);

  return { loading, error, initial };
};


export default useContentLoader;


