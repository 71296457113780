import { createSlice } from '@reduxjs/toolkit';

const I18nState = () => ({
    locale: 'et',
    locales: [],
    translations: {},
    translated_strings: {}
});

export const I18nSlice = createSlice({
    name: 'i18n',
    initialState: I18nState(),
    reducers: {
        setState(state, action) {
            Object.assign(state, action.payload);
        },
    },
});

export const { setState } = I18nSlice.actions;

export default I18nSlice.reducer;

export const setLocale = (locale) => async (dispatch) => {
    dispatch(setState({ locale: locale }));
};

export const setTranslatedStrings = (translated_strings) => async (dispatch) => {
    dispatch(setState({ translated_strings: translated_strings }));
};

export const getTranslatedString = (key = null) => (state, x) => {
    const { translated_strings, locale } = state.i18n;
    if (!key) return '';
    return translated_strings[key][locale];
};
